<template>
	<div id="center">
		<div class="up" >
				<div class="bg-color-black item" v-for="item in titleItem" :key="item.title" >
					<p class="ml-3 colorBlue fw-b fs-xl text">{{ item.title }}</p>
					<div class="float-r lh1">
						<dv-digital-flop class="dv-dig-flop ml-1 mt-2 pl-3" :config="item.number" />
					</div>
				</div>
		</div>
	</div>
</template>

<script>
	// import CenterChart from '@/components/echart/center/centerChartRate'

	export default {
		data() {
			return {
				titleItem: [
					// {
					// 	title: '人才储备数',
					// 	number: {
					// 		number: [3380008],
					// 		toFixed: 0,
					// 		textAlign: 'left',
					// 		content: '{nt}',
					// 		style: {
					// 			fontSize: 30
					// 		}
					// 	}
					// },
					// {
					// 	title: '今日新增候选人',
					// 	number: {
					// 		number: [297],
					// 		toFixed: 0,
					// 		textAlign: 'left',
					// 		content: '{nt}',
					// 		style: {
					// 			fontSize: 30
					// 		}
					// 	}
					// },
					// {
					// 	title: '月新增候选人数',
					// 	number: {
					// 		number: [297 * 30],
					// 		toFixed: 0,
					// 		textAlign: 'left',
					// 		content: '{nt}',
					// 		style: {
					// 			fontSize: 30
					// 		}
					// 	}
					// }
				],
				titleItems:[],
			}
		},
		// prop: ['talent'],
		components: {
			// CenterChart
		},
		created(){
			this.getStime()
		},
		mounted() {
			// this.changeTiming(this.talentTime);
			this.getTalent()
			// let that = this
			// setInterval(function(){that.saveTlent()},100)
			// this.getPerNum()
			
		},
		watch:{
			titleItem(){
				this.saveTlent()
			}
		},
		methods: {
			getPerNum(){
				// let that = this
				this.$axios({
					methods: 'get',
					// https://hui.ljmcode.top//cname
					url: this.url+'//createNum',
				
				}).then(function(res) {
					console.log(res.data)
					console.log(res.data.data.create_num,"create_num")
					// that.changeTiming(res.data.data.create_num);
				})
			},
			getStime(){
				let that = this
				this.$axios({
					methods: 'get',
					// https://hui.ljmcode.top//cname
					url: this.url+'//talentTime',
				
				}).then(function(res) {
					console.log(res)
					
					that.changeTiming(Number(res.data.data.talentTime),Number(res.data.data.createNum));
				})
			},
			changeTiming(talentTime,num) {
				let that = this
				console.log(talentTime,num,"talent4")
				setInterval(() => {
					that.changeNumber(num);
				}, talentTime);
			},
			changeNumber(num) {
				this.titleItem.forEach((item) => {
					item.number.number[0] += num;
					console.log(item.number);
					item.number = {
						...item.number
					};
					
				});
				
				this.saveTlent()
			},
			saveTlent() {
				// console.log("titleItem")
				// console.log(this.titleItem)
				// console.log("titleItem")
				let that = this
				this.$axios({
						method: 'post',
						url: this.url+'/api/talent/saveTlent',
						 data: {
							 titleItem:that.titleItem
						  }
					})
					.then(function() {
						// console.log(res)
						// console.log(res.data.msg)
					});
			},
			getTalent() {
				// console.log('T')
				let that = this
				that.$axios({
						method: 'get',
						url: this.url+'/api/talent/getList',
					})
					.then(function(res) {
						// console.log(res)
						that.titleItems = res.data.data
						that.titleItems.forEach((item) => {
							// console.log(item,index)
							if(item.status ==1) {
								that.titleItem.push(
									{
										id:item.id,
										title: item.cname,
										number: {
											number: [item.talents],
											toFixed: 0,
											textAlign: 'left',
											content: '{nt}',
											style: {
												fontSize: 30
											}
										}
									}
								)
							}
							
						});
					});
			}
		}
	}
</script>

<style lang="scss" scoped>
	#center {
		display: flex;
		flex-direction: column;

		.up {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-around;

			.item {
				border-radius: 6px;
				padding-top: 8px;
				margin-top: 8px;
				width: 32%;
				height: 80px;

				.dv-dig-flop {
					width: 150px;
					height: 30px;
				}
			}

			;

			.text {
				font-size: 23px;
				display: inline-block;
				line-height: 53px;
			}

			.lh1 {
				display: inline-block;
				line-height: 63px;
			}
		}

		.down {
			padding: 6px 4px;
			padding-bottom: 0;
			width: 100%;
			display: flex;
			height: 255px;
			justify-content: space-between;

			.bg-color-black {
				border-radius: 5px;
			}

			.ranking {
				padding: 10px;
				width: 59%;

				.dv-scr-rank-board {
					height: 225px;
				}
			}

			.percent {
				width: 40%;
				display: flex;
				flex-wrap: wrap;

				.item {
					width: 50%;
					height: 120px;

					span {
						margin-top: 8px;
						font-size: 14px;
						display: flex;
						justify-content: center;
					}

				}

				.water {
					width: 100%;

					.dv-wa-le-po {
						height: 120px;
					}
				}
			}
		}
	}
</style>
